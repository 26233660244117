import React, { Component } from 'react'
import "./login.css";
import CustomInput from "./components/CustomInput/CustomInput";
import Button from "./components/CustomButtons/Button";

import firebase from './firebase';

export default class login extends Component {

   handleChange =( e :any)=> {
    console.log()
    this.setState({[e.currentTarget.id]: e.target.value})
  
  };
  render() {
    return (
      <form className="form">
      <CustomInput
        labelText="Email"
        id="email"
        formControlProps={{
            id:"email",
            fullWidth: true,
        
            type:"password",
            onChange:this.handleChange
          }}
      
       
      />
      <CustomInput
        labelText="Password"
        id="password"
      
        formControlProps={{
            id:"password",
          fullWidth: true,
        
            onChange:this.handleChange
        }}
        inputProps={{
          type:"password",
        }}
          
       
      
      />
 {/* @ts-ignore */}
      <Button type="button"  color="warning" className="form__custom-button" 
      onClick={()=>  {
           //@ts-ignore
          if(this.state?.email && this.state?.password)  {
              //@ts-ignore
            firebase.auth().signInWithEmailAndPassword(this.state?.email,this.state?.password).then(r=>{
              window.location.href='/'
            }).catch(err=>console.error(err));
          }
          
      }}
      >
        Log in
      </Button>
    </form>
    )
  }
}




