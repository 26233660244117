import React, { useCallback, useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";



// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import firebase from '../../../firebase'
import Compress from "compress.js";

 const compress = new Compress();
// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");


  const [open, setOpen] = React.useState(false);

  // react crop
  const [fileurl, setFileUrl] = React.useState("");
  const [file, setFile] = React.useState<any>({});
  const [list, setlist] = React.useState<any>([]);
  const [selectedFile, setSelectedFile] = React.useState<any>("");
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 425 / 283 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(state)
    reset({
      name: "",
      website: "",
    });
    setState("")
    setFile("")
    setloading("");
    setFileUrl("");
    setSelectedFile("");
    // @ts-ignore
    setUpImg();
  };

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ name, website }: any) => {
    // call mutation
    setloading("true");
    let fileTemp:any = {};

    // upload file
    if (selectedFile && fileurl?.includes("blob")) {
      if (file?.fileUrl) {
        var fileRef = firebase.storage().refFromURL(file.fileUrl);

        await fileRef
          .delete()
          .then(function () {
            // File deleted successfully
            console.log("File Deleted");
          })
          .catch(function (error) {
            // Some Error occurred
          });
      }
      fileTemp = await uploadFile(selectedFile,firebase);
    }



  

    if (state) {
      console.log("updated Data", { name, website });
      console.log("update", state);

      await firebase
      .firestore()
      .collection("partners")
      .doc(state)
      .update({
        name, website, file:fileTemp
      });
      

      setloading("");
      setState("");
      setOpen(false);
      setFileUrl("");
      setSelectedFile("");
      setFile("")
      // @ts-ignore
      setUpImg();
      reset({
        name: "",
        website: "",
      });

      return;
    }
    await firebase
    .firestore()
    .collection("partners")
    .add({
      name, website, file:fileTemp
    });

    setloading("");
    setOpen(false);
    setFileUrl("");
    setSelectedFile("");
    setFile("")
    // @ts-ignore
    setUpImg();
    reset({
      name: "",
      website: "",
    });
  };
  const handleClick = async (props: any, e: any) => {
    // if (e === "delete") {
    //   const res = await remove({
    //     variables: { input: { id: props[4] } },
    //   })
    //     .then((r) => {
    //       console.log(props[5]);
    //       if (props[3])
    //         Storage.remove(props[3])
    //           .then((r) => console.log(r))
    //           .catch((err) => console.log(err));
    //     })
    //     .catch((err) => console.log(err));
    //   console.log(res);
    //   refetch();
    // }
    if (e === "update") {
      console.log("update", props);
      setOpen(true);
      reset({
        name: props[0],
        website: props[1],
      });
      setState(props[3]);

      setFileUrl(props[2]?.fileUrl);
      setFile(props[2]);
    }
  };

  const onFileSelected = async (event: any) => {
    // File Type or Size validation or make resize
    // Crop   // // 512∶209

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      // @ts-ignore
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }

    setSelectedFile(event.target.files[0]);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    // @ts-ignore
    const scaleX = image.naturalWidth / image.width;
    // @ts-ignore
    const scaleY = image.naturalHeight / image.height;
    // @ts-ignore
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    // @ts-ignore
    canvas.width = crop.width * pixelRatio;
    // @ts-ignore
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      // @ts-ignore
      crop.x * scaleX,
      // @ts-ignore
      crop.y * scaleY,
      // @ts-ignore
      crop.width * scaleX,
      // @ts-ignore
      crop.height * scaleY,
      0,
      0,
      // @ts-ignore
      crop.width,
      // @ts-ignore
      crop.height
    );
    // @ts-ignore
    canvas.toBlob(
      async (blob:any) => {
        blob.name = selectedFile.name;
        console.log(selectedFile.size);
        console.log("blob", blob);
        const croppedFile = new File([blob], selectedFile.name, {
          type: blob.type,
        });
        const resizedBlob = await resizeImageFn(croppedFile);
           //@ts-ignore
        const resizeFile = new File([resizedBlob], selectedFile.name, {
             //@ts-ignore
          type: resizedBlob.type,
        });
        console.log(resizeFile.size);

        setSelectedFile(resizeFile);
        setFileUrl(URL.createObjectURL(resizeFile));
      },
      "image/jpeg",
      1
    );
  }, [completedCrop]);
  const uploadFile = async (selectedFile: any, firebase: any) => {
    if (!selectedFile) return null;

    //create a storage reference
    const storage = firebase
      .storage()
      .ref("/partners/" + new Date().getTime() +selectedFile.name);
    //upload file
    const upload = storage.put(selectedFile);

    return new Promise((resolve, reject) => {
      //update progress bar
      upload.on(
        "state_changed",
        function progress(snapshot: any) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(percentage);
        },

        function error(err: any) {
          reject(err);
        },

        async function complete() {
          resolve({
            fileUrl: await storage.getDownloadURL(),
            fullPath: storage.fullPath,
          });
        }
      );
    });
  };

  async function resizeImageFn(file: any) {
    const resizedImage = await compress.compress([file], {
      size: 1, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 1024, // the max width of the output image, defaults to 1920px
      maxHeight: 1024, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFile;
  }


  useEffect(() => {
    const array:any  = [];
     firebase
    .firestore()
    .collection("partners")
    .get()
    .then((r: any) => {
      const docs = r.docs;
      for (const doc of docs) {
        //@ts-ignore
        array.push([
          doc.data().name,
          doc.data().website,
          doc.data()?.file,
          doc.id,
        ]);
      }
      setlist(array);
    });


    
    return () => {
      
    }
  }, [open])




  if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );



  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Partners</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add a Partner
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
              >
                {/* @ts-ignore */}
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogTitle id="form-dialog-title">
                  Add New Partner
                </DialogTitle>

                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Name"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Name is required" }}
                    />
                     <Controller
                      name="website"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Website Url"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: false}}
                    />

                 

                    <Button variant="contained" component="label">
                      Upload Picture
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={onFileSelected}
                      />
                    </Button>
                    {!fileurl?.includes("blob") && (
                      <img src={fileurl} width="100%" alt="image" />
                    )}

                    {/* @ts-ignore  */}
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c: any) => setCrop(c)}
                      onComplete={(c: any) => setCompletedCrop(c)}
                    />
                    <br />
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          // @ts-ignore
                          width: Math.round(completedCrop?.width ?? 0),
                          // @ts-ignore
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name","Website", "Image Url","Actions"]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/* id: ID!
name: String
title: String
keywords: String
website: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
