import React from 'react'
import Layout from '../components/Layout';
import News from '../components/views/news';

export default function Index() {
 

  return (
    <Layout>
       
      <News/>
  
    </Layout>
  );
}
