import React from "react";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import firebase from '../firebase'
import { useHistory } from "react-router-dom";



import { Theme, makeStyles } from "@material-ui/core/styles";


import dropdownStyle from "../assets/jss/material-dashboard-react/components/dropdownStyle.js";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) => ({
  ...dropdownStyle(theme),

  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});
// @ts-ignore
const useStyles = makeStyles(styles);

function Header(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const { onDrawerToggle } = props;

  const [value, setValue] = React.useState(0);

  const [state, setState] = React.useState({});

  const handleChange = (event: any, newValue: number) => {
    console.log(event.currentTarget.id);

    history.push(event.currentTarget.id);
    console.log(history.location);
    setValue(newValue);
  };

  React.useEffect(() => {
    if (
      history.location.pathname === "/settings" ||
      history.location.pathname === "/services" ||
      history.location.pathname === "/testimonials" ||
      history.location.pathname === "/news"  ||
      history.location.pathname === "/partners"  || 
      history.location.pathname === "/parentServices"
    ) {
      setValue(0);
    }
    if (
      history.location.pathname === "/settings/pages" ||
      history.location.pathname === "/services"
    ) {
      setValue(1);
    }
    if (history.location.pathname === "/settings/modules" ||  history.location.pathname === "/subServices") {
      setValue(2);
    }
    if (history.location.pathname === "/settings/sliders") {
      setValue(3);
    }
  }, []);

  React.useEffect(() => {
    if (history.location.pathname.includes("settings")) {
      setState({
        title: "Settings",
        menuArray: [
          { name: "Web Settings", url: "/settings" },
          { name: "Pages", url: "/settings/pages" },
          { name: "Module", url: "/settings/modules" },
          { name: "Sliders", url: "/settings/sliders" },
        ],
      });
    }
    if (history.location.pathname.includes("services") || history.location.pathname.includes("subServices") || history.location.pathname.includes("parentServices")) {
      setState({
        title: "Services",
        menuArray: [
          { name: "Parent Services", url: "/parentServices" },
          { name: "Services", url: "/services" },
          { name: "Sub Services", url: "/subServices" },
        ],
      });
    }

    if (history.location.pathname.includes("testimonials")) {
      setState({
        title: "Testimonials",
        menuArray: [
          { name: "Testimonials", url: "/testimonials" },
        
        ],
      });
    }
    if (history.location.pathname.includes("news")) {
      setState({
        title: "News",
        menuArray: [
          { name: "News", url: "/news" },
        
        ],
      });
    }

    if (history.location.pathname.includes("partners")) {
      setState({
        title: "Partners",
        menuArray: [
          { name: "Partners", url: "/partners" },
        
        ],
      });
    }

    if (history.location.pathname.includes("prices")) {
      setState({
        title: "Prices",
        menuArray: [
          { name: "Prices", url: "/prices" },
        
        ],
      });
    }

    return () => {};
  }, [history.location]);

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event: any) => {
    // @ts-ignore
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = (e: any) => {
    setOpenProfile(null);
    if (e.currentTarget.id === "logout") {
      firebase.auth().signOut()
      window.location.reload();
  
    }
  };

  if (!history.location)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Link className={classes.link} href="#" variant="body2">
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                className={classes.iconButtonAvatar}
                onClick={handleClickProfile}
              >
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />

                <Poppers
                  open={Boolean(openProfile)}
                  anchorEl={openProfile}
                  transition
                  disablePortal
                  className={
                    classNames({ [classes.popperClose]: !openProfile }) +
                    " " +
                    classes.popperNav
                  }
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      // @ts-ignore
                      id="profile-menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseProfile}>
                          <MenuList role="menu">
                            <MenuItem
                              onClick={handleCloseProfile}
                              className={classes.dropdownItem}
                              id="menu"
                            >
                              Profile
                            </MenuItem>
                            <MenuItem
                              onClick={handleCloseProfile}
                              className={classes.dropdownItem}
                              id="settings"
                            >
                              Settings
                            </MenuItem>
                            <Divider light />
                            <MenuItem
                              onClick={handleCloseProfile}
                              className={classes.dropdownItem}
                              id="logout"
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Poppers>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {/* @ts-ignore */}
                {state?.title}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={value} onChange={handleChange} textColor="inherit">
          {/* @ts-ignore */}
          {state?.menuArray?.map((menu: any) => {
            return (
              <Tab
                textColor="inherit"
                key={menu.url}
                id={menu.url}
                label={menu.name}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
