import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  MenuItem,
  TextareaAutosize,
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { nanoid } from "nanoid";
// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import firebase from "../../../firebase";

/* 


   id:ID! 
  name:String
  description:String
  imageUrl:File
  createdDate: AWSDateTime
  updatedDate: AWSDateTime

*/

var slugify = require("slugify");

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");

  const [fileurl, setFileUrl] = React.useState("");
  const [file, setFile] = React.useState<any>();
  const [selectedFile, setSelectedFile] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [list, setlist] = React.useState<any>([]);

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    reset({
      parentId:"",
      title: "",
      description: "",
    });
    setFileUrl("");
    setloading("");
    setFile("");
    setSelectedFile("");
    setOpen(false);
  };

  const onSubmit = async ({ title, description, parentId }: any) => {
    // call mutation

    let fileTemp: any = {};
    setloading("true");
   
    // upload file
    if (selectedFile && fileurl?.includes("blob")) {
      // @ts-ignore
      console.log(selectedFile);
      if(file.fileUrl)  {
        var fileRef = firebase.storage().refFromURL(file.fileUrl);
     
       await fileRef.delete().then(function () {
  
          // File deleted successfully
          console.log("File Deleted")
      }).catch(function (error) {
          // Some Error occurred
      });

  
      }
      fileTemp = await uploadFile(selectedFile, firebase).then((r) => r);
    }

    console.log({ title, description });

    const slug = slugify(title, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: false, // strip special characters except replacement, defaults to `false
    });

    // await firebase
    // .firestore()
    // .collection("services")
    // .doc(parentId)
    // .collection("list")
    // .where('slug','==',slug).get().then((r:any)=> {
    //   if(r.docs>0) return ;
    // })

   

    if (state) {
      console.log("updated Data", { title, description, parentId, fileTemp });
      console.log("update", state);

      await firebase
      .firestore()
      .collection("services")
      .doc(parentId)
      .collection("list")
      .doc(state)
      .update({
        title,
        slug,
        description,
        file: fileTemp.fileUrl ? fileTemp:  file,
      })
      .then((r) => console.info(r));

      // Delete first image

      setloading("");
      setState("");
      setOpen(false);
      setFileUrl("");
      setFile("");
      setSelectedFile("");
      // @ts-ignore
      setUpImg();
      reset({
        title: "",
        description: "",
      });

      return;
    }
    console.log("add Data", { title, description, parentId, fileTemp, slug });
    await firebase
      .firestore()
      .collection("services")
      .doc(parentId)
      .collection("list")
      .add({
        title,
        slug,
        description,
        file: fileTemp
      })
      .then((r) => console.info(r));

    setloading("");
    setOpen(false);
    setFileUrl("");
    setFile("");
    setSelectedFile("");

    reset({
      title: "",
      description: "",
    });
  };

  const handleClick = async (props: any, e: any) => {
    // if (e === "delete") {
    //   await remove({
    //     variables: { input: { id: props[0] } },
    //   })
    //     .then((r) => {
    //       if (props[4])
    //         Storage.remove(props[4])
    //           .then((r) => console.log(r))
    //           .catch((err) => console.log(err));
    //     })
    //     .catch((err) => console.log(err));
    //   refetch();
    // }
    if (e === "update") {
      setOpen(true);
      console.log(props);
      reset({
        parentId: props[0],
        title: props[1],
        description: props[4],
      });
      setFileUrl(props[3].fileUrl);
      setFile(props[3]);
      setState(props[5]);
    }
  };

  const onFileSelected = async (event: any) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    // File Type or Size validation or make resize
    setFileUrl(URL.createObjectURL(selectedFile));
  };

  const uploadFile = async (selectedFile: any, firebase: any) => {
    if (!selectedFile) return null;

    //create a storage reference
    const storage = firebase
      .storage()
      .ref("/ServicesIcon/"+new Date().getTime() + selectedFile.name);
    //upload file
    const upload = storage.put(selectedFile);

    return new Promise((resolve, reject) => {
      //update progress bar
      upload.on(
        "state_changed",
        function progress(snapshot: any) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(percentage);
        },

        function error(err: any) {
          reject(err);
        },

        async function complete() {
          resolve({
            fileUrl: await storage.getDownloadURL(),
            fullPath: storage.fullPath,
          });
        }
      );
    });
  };

  useEffect(() => {
    (async () => {
      const array: any = [];
      const pAll = ["business-immigration", "personal-immigration"].map(
        async (parentId) => {
          await firebase
            .firestore()
            .collection("services")
            .doc(parentId)
            .collection("list")
            .get()
            .then((r) => {
              const docs = r.docs;
              for (const doc of docs) {
                //@ts-ignore
                array.push([
                  parentId,
                  doc.data().title,
                  doc.data().slug,
                  doc.data()?.file,
                  doc.data().description,
                  doc.id
                ]);
                console.log(
                  parentId,
                  doc.data().title,
                  doc.data().slug,
                  doc.data().description,
                  doc.data()?.file,
                  doc.id
                );
              }
            });
        }
      );

      await Promise.all(pAll);

      setlist(array);
    })();

    return () => {};
  }, [open]);

  if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );

 

  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Services</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add Service
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">
                 {state ?"Update": "Add New"} Service
                </DialogTitle>
                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="parentId"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          select
                          label="Select Parent Category"
                          defaultValue=""
                          className="w-100"
                          {...field}
                        >
                          <MenuItem key={0} value={"personal-immigration"}>
                            Personal Immigration
                          </MenuItem>
                          <MenuItem key={0} value={"business-immigration"}>
                            Business Immigration
                          </MenuItem>
                        </TextField>
                      )}
                      rules={{ required: true }}
                    />

                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Title"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Title is required" }}
                    />

                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextareaAutosize
                          value={value}
                          className="form-control w-100"
                          onChange={onChange}
                          rowsMax={4}
                          aria-label="Description"
                          placeholder="Description"
                          rows="5"
                        />
                      )}
                      rules={{ required: true }}
                    />

                    <Button variant="contained" component="label">
                      Upload an Icon
                      <input type="file" hidden onChange={onFileSelected} />
                    </Button>

                    {fileurl && <img src={fileurl} height="50" alt="image" />}

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "ParentId",
                  "Title",
                  "Slug",
                  "File",
                  "Description",
                  "Actions",
                ]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/* id: ID!
name: String
title: String
keywords: String
description: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
