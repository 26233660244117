
import { Redirect, Route, useHistory ,Switch} from "react-router-dom";
import Settings from "./pages/Settings";
import Pages from "./pages/Pages";
import Modules from "./pages/Modules";

import Partners from "./pages/Partners";
import Services from "./pages/Services";
import ParentServices from "./pages/ParentServices";
import SubServices from "./pages/SubServices";
import Sliders from "./pages/Sliders";
import Testimonials from "./pages/Testimonials";
import News from "./pages/News";
import Prices from "./pages/Prices";
import Login from './login';

import firebase from './firebase';
import { useEffect, useState } from "react";

function App() {
  const history = useHistory();
  const [loggedIn, setloggedIn] = useState(false);

  firebase.auth().onAuthStateChanged((r:any)=>{
    
    if(r?.email)  {
      setloggedIn(true)
 
    }else {
      setloggedIn(false)
      history.push('/login')
    }
  })
  
  return (
    <>
      <Switch>
    
      <Route exact path="/"  component={Settings} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/settings/pages" component={Pages} />
      <Route exact path="/settings/modules" component={Modules} />
      <Route exact path="/subServices" component={SubServices} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/parentServices" component={ParentServices} />
      <Route exact path="/settings/sliders" component={Sliders} />
      <Route exact path="/testimonials" component={Testimonials} />
      <Route exact path="/news" component={News} />
      <Route exact path="/partners" component={Partners} />
      <Route exact path="/prices" component={Prices} />
      <Route  path="*"  component={Settings}  />
     
      </Switch>
    </>
  );
}

export default App;
