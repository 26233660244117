import React, { useCallback, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  TextareaAutosize,
  List,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import firebase from "../../../firebase";

// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Chips from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "react-quill/dist/quill.snow.css"; // ES6

import ReactQuill from "react-quill"; // Typescript
import { nanoid } from "nanoid";
import Compress from "compress.js";

const slugify = require("slugify");

const compress = new Compress();

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState<any>("");
  const [chips, setChips] = React.useState<any>([]);

  const [open, setOpen] = React.useState(false);

  // react crop
  const [fileurl, setFileUrl] = React.useState("");
  const [file, setFile] = React.useState<any>({});
  const [list, setlist] = React.useState<any>([]);
  const [selectedFile, setSelectedFile] = React.useState<any>("");
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 600 / 425 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      title: "",
      description: "",
      serviceId: "",
    });
    setState("");
    setloading("");
    setFileUrl("");
    setFile("");
    setSelectedFile("");
    // @ts-ignore
    setUpImg();
  };

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ title, description, serviceId }: any) => {
    console.log(serviceId);
    const serviceID = serviceId.split(':')[0]
    const parentId = serviceId.split(':')[1]

   

    // call mutation
    setloading("true");

    let fileTemp: any = {};

    // upload file
    if (selectedFile && fileurl?.includes("blob")) {
      console.log(selectedFile);
      if (file?.fileUrl) {
        var fileRef = firebase.storage().refFromURL(file.fileUrl);

        await fileRef
          .delete()
          .then(function () {
            // File deleted successfully
            console.log("File Deleted");
          })
          .catch(function (error) {
            // Some Error occurred
          });
      }
      fileTemp = await uploadFile(selectedFile, firebase).then((r) => r);
    }



    const slug = slugify(title, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: false, // strip special characters except replacement, defaults to `false
    });

    // await firebase
    //   .firestore()
    //   .collection("services")
    //   .doc(parentId)
    //   .collection("list")
    //   .doc(serviceID)
    //   .collection("list")
    //   .where("slug", "==", slug)
    //   .get()
    //   .then((r: any) => {
    //     if (r.docs > 0)
    //       throw new Error("Title is duplicated please change the title");
    //   });
    const array: any = [];
    if (state) {
      console.log("updated Data", { title, description, serviceID });
      console.log("update", state);

   
      await firebase
        .firestore()
        .collection("services")
        .doc(parentId)
        .collection("list")
        .doc(serviceID)
        .collection("list")
        .doc(state)
        .update({
          file: fileTemp.fileUrl ? fileTemp:  file,
          title,
          description,
          slug,
        });

     
      await reloadList(parentId,serviceID, array);

      setloading("");
      setState("");
      setOpen(false);
      setFileUrl("");
      setFile("");
      setSelectedFile("");
      // @ts-ignore
      setUpImg();
      reset({
        title: "",
        description: "",
        serviceId: "",
      });

      return;
    }
    console.log(slug, "added");
    await firebase
        .firestore()
        .collection("services")
        .doc(parentId)
        .collection("list")
        .doc(serviceID)
        .collection("list")
        .add({
          file: fileTemp,
          title,
          description,
          slug,
        });
        await reloadList(parentId,serviceID, array);
    setloading("");
    setOpen(false);
    setFileUrl("");
    setFile("");
    setSelectedFile("");
    // @ts-ignore
    setUpImg();
    reset({
      title: "",
      description: "",
      serviceId: "",
    });
  };

  const handleClick = async (props: any, e: any) => {
    // if (e === "delete") {

    //   const res = await remove({
    //     variables: { input: { id: props[2] } },
    //   })
    //     .then((r) => {
    //       console.log(props[5]);
    //       if (props[5])
    //         Storage.remove(props[5])
    //           .then((r) => console.log(r))
    //           .catch((err) => console.log(err));
    //     })
    //     .catch((err) => console.log(err));
    //   console.log(res);
    //   refetch();
    // }
    if (e === "update") {
      console.log("update", props);
      setOpen(true);
      reset({
        title: props[0],
        description: props[1],
        serviceId: props[4].id+':'+props[4].parentId,
      });
      // item .id /parentId
      setState(props[3]);

      setFileUrl(props[2]?.fileUrl);
      setFile(props[2]);
    }
  };

  const onFileSelected = async (event: any) => {
    // File Type or Size validation or make resize
    // Crop   // // 512∶209

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      // @ts-ignore
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }

    setSelectedFile(event.target.files[0]);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    // @ts-ignore
    const scaleX = image.naturalWidth / image.width;
    // @ts-ignore
    const scaleY = image.naturalHeight / image.height;
    // @ts-ignore
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    // @ts-ignore
    canvas.width = crop.width * pixelRatio;
    // @ts-ignore
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      // @ts-ignore
      crop.x * scaleX,
      // @ts-ignore
      crop.y * scaleY,
      // @ts-ignore
      crop.width * scaleX,
      // @ts-ignore
      crop.height * scaleY,
      0,
      0,
      // @ts-ignore
      crop.width,
      // @ts-ignore
      crop.height
    );
    // @ts-ignore
    canvas.toBlob(
      async (blob: any) => {
        blob.name = selectedFile.name;
        console.log(selectedFile.size);
        console.log("blob", blob);
        const croppedFile = new File([blob], selectedFile.name, {
          type: blob.type,
        });
        const resizedBlob = await resizeImageFn(croppedFile);
        //@ts-ignore
        const resizeFile = new File([resizedBlob], selectedFile.name, {
          //@ts-ignore
          type: resizedBlob.type,
        });
        console.log(resizeFile.size);

        setSelectedFile(resizeFile);
        setFileUrl(URL.createObjectURL(resizeFile));
      },
      "image/jpeg",
      1
    );
  }, [completedCrop]);

  const uploadFile = async (selectedFile: any, firebase: any) => {
    if (!selectedFile) return null;

    //create a storage reference
    const storage = firebase
      .storage()
      .ref("/SubServices/" + new Date().getTime() +selectedFile.name);
    //upload file
    const upload = storage.put(selectedFile);

    return new Promise((resolve, reject) => {
      //update progress bar
      upload.on(
        "state_changed",
        function progress(snapshot: any) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(percentage);
        },

        function error(err: any) {
          reject(err);
        },

        async function complete() {
          resolve({
            fileUrl: await storage.getDownloadURL(),
            fullPath: storage.fullPath,
          });
        }
      );
    });
  };

  async function reloadList(parentId:any,serviceId: any, array: any) {
    await firebase
      .firestore()
      .collection("services")
      .doc(parentId)
      .collection("list")
      .doc(serviceId)
      .collection("list")
      .get()
      .then((r: any) => {
        const docs = r.docs;

        for (const doc of docs) {
          //@ts-ignore
          array.push([
            doc.data().title,
            doc.data().description,
            doc.data()?.file,
            doc.id,
            { parentId: parentId, id: serviceId },
          ]);
        }
      });
    setlist(array);
  }

  async function resizeImageFn(file: any) {
    const resizedImage = await compress.compress([file], {
      size: 1, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 1024, // the max width of the output image, defaults to 1920px
      maxHeight: 1024, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFile;
  }

  React.useEffect(() => {
    (async () => {
      const array: any = [];
      const pAll = ["business-immigration", "personal-immigration"].map(
        async (parentId) => {
          await firebase
            .firestore()
            .collection("services")
            .doc(parentId)
            .collection("list")
            .get()
            .then((r) => {
              const docs = r.docs;
              for (const doc of docs) {
                //@ts-ignore
                array.push({
                  parentId,
                  ...doc.data(),
                  id: doc.id,
                });
                console.log(
                  parentId,
                  doc.data().title,
                  doc.data().slug,
                  doc.data().description,
                  doc.data()?.file,
                  doc.id
                );
              }
            });
        }
      );

      await Promise.all(pAll);

      setChips(array);
    })();

    return () => {};
  }, []);

  if (!chips.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );

  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <h5>Select service</h5>
          {chips.map((item: any, i: number) => {
            console.log(item);
            return (
              <Chips key={i} label={item.title} onClick={handleChips(item)} />
            );
          })}{" "}
        </GridItem>

        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Sub Services</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add New Sub Service
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
              >
                {/* @ts-ignore */}
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogTitle id="form-dialog-title">
                  {!state ? "Add New" : "Update"} Services
                </DialogTitle>

                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="serviceId"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          select
                          label="Parent service"
                          defaultValue=""
                          className="w-100"
                          {...field}
                        >
                          {chips?.map((option: any) => (
                            <MenuItem key={option.id} value={option.id+':'+option.parentId}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      rules={{ required: true }}
                    />

                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Title"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Title is required" }}
                    />

                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ReactQuill
                          style={{
                            height: 400,
                          }}
                          className="w-100 mb-5"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      rules={{ required: "Description is required" }}
                    />

                    <Button variant="contained" component="label">
                      Upload Picture
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={onFileSelected}
                      />
                    </Button>
                    {!fileurl?.includes("blob") && (
                      <img src={fileurl} width="100%" alt="image" />
                    )}

                    {/* @ts-ignore  */}
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c: any) => setCrop(c)}
                      onComplete={(c: any) => setCompletedCrop(c)}
                    />
                    <br />
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          // @ts-ignore
                          width: Math.round(completedCrop?.width ?? 0),
                          // @ts-ignore
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Title", "Description", "Image", "Actions"]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );

  function handleChips(
    item: any
  ): React.MouseEventHandler<HTMLDivElement> | undefined {
    return async (e: any) => {
      console.log(item);
      const array: any = [];
      await firebase
        .firestore()
        .collection("services")
        .doc(item.parentId)
        .collection("list")
        .doc(item.id)
        .collection("list")
        .get()
        .then((r: any) => {
          const docs = r.docs;

          for (const doc of docs) {
            //@ts-ignore
            array.push([
              doc.data().title,
              doc.data().description,
              doc.data()?.file,
              doc.id,
              item,
            ]);
          }
        });
      setlist(array);
    };
  }
}

/* id: ID!
name: String
title: String
keywords: String
description: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
