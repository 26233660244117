import React from 'react'
import Layout from '../components/Layout';
import SubServices from '../components/views/subServices';

export default function Index() {
 

  return (
    <Layout>
       
      <SubServices/>
  
    </Layout>
  );
}
