// Import the functions you need from the SDKs you need
import * as app from 'firebase';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrWDG0Tv0FGqNIw2oMEmiMiNg2dVL1JAU",
  authDomain: "corporate-immigration-uk.firebaseapp.com",
  projectId: "corporate-immigration-uk",
  storageBucket: "corporate-immigration-uk.appspot.com",
  messagingSenderId: "281714448042",
  appId: "1:281714448042:web:8ecbd12ec7991f737c62d3",
  measurementId: "G-T46PTBJ28K"
};

// Initialize Firebase
const firebase_app =  app.apps.length>0 ? app:app.initializeApp(firebaseConfig);
export default firebase_app;