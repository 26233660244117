import React from 'react'
import Layout from '../components/Layout';
import Partners from '../components/views/partners';

export default function Index() {
 

  return (
    <Layout>
       
      <Partners/>
  
    </Layout>
  );
}
