import React, { useCallback,useState,useEffect,useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import { nanoid } from "nanoid";
import Compress from "compress.js";
import firebase from '../../../firebase'


const compress = new Compress();

// TODO sliders slides model

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
}));
var slugify = require("slugify");
export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");
  const [list, setlist] = React.useState<any>([]);
  const [fileurl, setFileUrl] = React.useState("");
  const [file, setFile] = React.useState<any>();
  const [selectedFile, setSelectedFile] = React.useState<any>("");
  const [open, setOpen] = useState(false);
  // react crop
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect:512/209 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (state) {
      reset({
        title: "",
        description: "",
        buttonUrl:""
      });
    }
    setState("");
    setFileUrl("");
    setFile("");
    setSelectedFile("");
    setOpen(false);
  };

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ title, description, buttonUrl }: any) => {

    setloading("true");

    let fileTemp: any = {};

    // upload file
    if (selectedFile && fileurl?.includes("blob")) {
      console.log(selectedFile);
      if (file?.fileUrl) {
        var fileRef = firebase.storage().refFromURL(file.fileUrl);

        await fileRef
          .delete()
          .then(function () {
            // File deleted successfully
            console.log("File Deleted");
          })
          .catch(function (error) {
            // Some Error occurred
          });
      }
      fileTemp = await uploadFile(selectedFile, firebase).then((r) => r);
    }

    if (state) {
      console.log("updated Data", { title, description,state });
 

   
      await firebase
        .firestore()
        .collection("sliders")
        .doc(state)
        .update({
          file: fileTemp.fileUrl ? fileTemp:  file,
          title,
          description,
          button_url:buttonUrl,
        });

     
      setloading("");
      setState("");
      setOpen(false);
      setFileUrl("");
      setFile("");
      setSelectedFile("");
      // @ts-ignore
      setUpImg();
      reset({
        title: "",
        description: "",
        serviceId: "",
      });

      return;
    }
   
    await firebase
        .firestore()
        .collection("sliders")
        .add({
          file: fileTemp,
          title,
          description,
          button_url:buttonUrl
    
        });
       

    setloading("");
    setOpen(false);
    setFileUrl("");
    setFile("");
    setSelectedFile("");
    // @ts-ignore
    setUpImg();
    reset({
      title: "",
      description: "",
      serviceId: "",
    });






  }
  const handleClick = async (props: any, e: any) => {
    if (e === "delete") {
      firebase.firestore()
        .collection("sliders")
        .doc(props[4]).delete()
        .then(async (r:any) => {
          if(!props[2]?.fileUrl) return;
          var fileRef = firebase.storage().refFromURL(props[2].fileUrl);

          await fileRef
            .delete()
            .then(function () {
              // File deleted successfully
              console.log("File Deleted");
            })
            .catch(function (error) {
              // Some Error occurred
            });
         
        })
        .catch((err) => console.log(err));
    
    }
    if (e === "update") {
      setOpen(true);
      reset({
        title: props[0],
        description: props[1],
        buttonUrl: props[3],
      });
      setFileUrl(props[2].fileUrl);
      setFile(props[2]);
      setState(props[4]);
    }
  };

  const onFileSelected = async (event: any) => {


    // File Type or Size validation or make resize
    // Crop   // // 512∶209

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      // @ts-ignore
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(event.target.files[0]);
    }

    setSelectedFile(event.target.files[0]);


  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

  
  

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    // @ts-ignore
    const scaleX = image.naturalWidth / image.width;
     // @ts-ignore
    const scaleY = image.naturalHeight / image.height;
     // @ts-ignore
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
     // @ts-ignore
    canvas.width = crop.width * pixelRatio;
     // @ts-ignore
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
       // @ts-ignore
      crop.x * scaleX,
       // @ts-ignore
      crop.y * scaleY,
       // @ts-ignore
      crop.width * scaleX,
       // @ts-ignore
      crop.height * scaleY,
      0,
      0,
       // @ts-ignore
      crop.width,
       // @ts-ignore
      crop.height
    );
     // @ts-ignore
    canvas.toBlob(async blob => {
      blob.name = selectedFile.name;
      console.log(selectedFile.size);
      console.log("blob",blob)
      const croppedFile = new File([blob], selectedFile.name, {
        type: blob.type,
      });
      const resizedBlob = await resizeImageFn(croppedFile);
      //@ts-ignore
      const resizeFile = new File([resizedBlob], selectedFile.name, {
           //@ts-ignore
        type: resizedBlob.type,
      });
      console.log(resizeFile.size);
  
      setSelectedFile(resizeFile);
       setFileUrl(URL.createObjectURL(resizeFile));
      
        
    }, 'image/jpeg', 1);
  }, [completedCrop]);

  const uploadFile = async (selectedFile: any, firebase: any) => {
    if (!selectedFile) return null;

    //create a storage reference
    const storage = firebase
      .storage()
      .ref("/sliders/" + new Date().getTime() +selectedFile.name);
    //upload file
    const upload = storage.put(selectedFile);

    return new Promise((resolve, reject) => {
      //update progress bar
      upload.on(
        "state_changed",
        function progress(snapshot: any) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(percentage);
        },

        function error(err: any) {
          reject(err);
        },

        async function complete() {
          resolve({
            fileUrl: await storage.getDownloadURL(),
            fullPath: storage.fullPath,
          });
        }
      );
    });
  };

  async function resizeImageFn(file: any) {
    const resizedImage = await compress.compress([file], {
      size: 1, // the max size in MB, defaults to 2MB
      quality: 0.75, // the quality of the image, max is 1,
      maxWidth: 1024, // the max width of the output image, defaults to 1920px
      maxHeight: 1024, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFile;
  }

 

    useEffect(() => {
      const array:any  = [];
       firebase
      .firestore()
      .collection("sliders")
      .get()
      .then((r: any) => {
        const docs = r.docs;

        for (const doc of docs) {
          //@ts-ignore
          array.push([
            doc.data().title,
            doc.data().description,
            doc.data()?.file,
            doc.data()?.button_url,
            doc.id,
          ]);
        }
        setlist(array);
      });
 

      
      return () => {
        
      }
    }, [open])

    if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );



  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Slides </h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add New Slide
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">Add New Slide</DialogTitle>
                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="title"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          className="w-100"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: true }}
                    />

                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextareaAutosize
                          value={value}
                          className="form-control w-100"
                          onChange={onChange}
                          rowsMax={4}
                          aria-label="Description"
                          placeholder="Description"
                          rows="5"
                        />
                      )}
                      rules={{ required: "Description is required" }}
                    />

                    <Controller
                      name="buttonUrl"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Enter Button Url"
                          variant="filled"
                          className="w-100"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Value is required" }}
                    />

                    <Button variant="contained" component="label">
                      Upload Picture
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={onFileSelected}
                      />
                    </Button>
                    {!fileurl?.includes("blob") && <img src={fileurl} width="100%" alt="image" />}
             

                       {/* @ts-ignore  */}
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c:any) => setCrop(c)}
                      onComplete={(c:any) => setCompletedCrop(c)}
                    />
                    <br />
                    <div>
                 
                      <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                          // @ts-ignore
                          width: Math.round(completedCrop?.width ?? 0),
                            // @ts-ignore
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "title",
                  "description",
                  "file",
                  "buttonUrl",
                  "Actions",
                ]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/* 

  id: ID!
  title: String!
  description: String
  name: String
  allowButton:Boolean
  buttonUrl:String
  isActive:Boolean
  imageUrl:String
  rank:Int
  createdDate: AWSDateTime
  updatedDate: AWSDateTime

*/
