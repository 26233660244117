import React from 'react'
import Layout from '../components/Layout';
import Services from '../components/views/services';

export default function Index() {
 

  return (
    <Layout>
       
      <Services/>
  
    </Layout>
  );
}
