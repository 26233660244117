import React from 'react'
import Layout from '../components/Layout';
import Testimonials from '../components/views/testimonials';

export default function Index() {
 

  return (
    <Layout>
       
      <Testimonials/>
  
    </Layout>
  );
}
