import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import firebase from "../../../firebase";


// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");
  const [list, setlist] = React.useState<any>([]);
 


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(state)
    reset({
      name:'', title:'', job:''
    });
    setState("");
  };
 

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ name, job, comment }: any) => {
   

    setloading("true");

    if (state) {

      await firebase
        .firestore()
        .collection("testimonials")
        .doc(state)
        .update({
          name, job, comment
        });


      setloading("");
      setState("");
      setOpen(false);
      reset({
        name:'', title:'', job:''
      });
      return ;
    }

    await firebase
    .firestore()
    .collection("testimonials")
    .add({
      name, job, comment
    });


    setloading("");
    setOpen(false);
    reset({
      name:'', title:'', job:''
    });
  };
  const handleClick = async (props: any, e: any) => {
    // if (e === "delete") {
    //  await remove({
    //     variables: { input: { id: props[3] } },
    //   }).catch(err=>console.log(err));
    //   refetch()
    // }
    if (e === "update") {
      setOpen(true);
      reset({
        name:props[0], comment: props[1], job:props[2] 
      });
      setState(props[3]);
      
    }
  };

  
  React.useEffect(() => {
    const array:any  = [];
     firebase
    .firestore()
    .collection("testimonials")
    .get()
    .then((r: any) => {
      const docs = r.docs;

      for (const doc of docs) {
        //@ts-ignore
        array.push([
          doc.data().name,
          doc.data().comment,
          doc.data().job,
          doc.id,
        ]);
      }
      setlist(array);
    });


    
    return () => {
      
    }
  }, [open])

  if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );


  return (
    <>


    
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Customer Reviews</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add New Pages
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">
                  Add New Setting
                </DialogTitle>
                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Full Name"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Full Name is required" }}
                    />
                    <Controller
                      name="job"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Job Title"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Job Title is required" }}
                    />

                  

                    <Controller
                      name="comment"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextareaAutosize
                          value={value}
                          className="form-control w-100"
                          onChange={onChange}
                          rowsMax={4}
                          aria-label="comment"
                          placeholder="Comment"
                          rows="5"
                        />
                      )}
                      rules={{ required: "Comment is required" }}
                    />

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Full Name",
                  "Comment",
                  "Job title",
                  "Actions",
                ]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    

    </>
  );
}

/* id: ID!
name: String
title: String
keywords: String
description: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
