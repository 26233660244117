import React, { useCallback, useEffect, useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  TextareaAutosize
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import firebase from '../../../firebase'

//@ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");
  const [list, setlist] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      title: "",
      description: "",
      serviceCategoryId: "",
    });
  };

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ title, description, keywords }: any) => {
    // call mutation
    setloading("true");
    await firebase.firestore().collection('services').doc(state).update({
      title, description, keywords
    }).then(r=>r)
    
    setloading("");
    setState("");
    setOpen(false);
  
    reset({
      title: "",
      description: "",
      keywords: "",
    });
  };

  const handleClick = async (props: any, e: any) => {
    // if (e === "delete") {
      
   
    //   const res = await remove({
    //     variables: { input: { id: props[2] } },
    //   })
    //     .then((r) => {
    //       console.log(props[5]);
    //       if (props[5])
    //         Storage.remove(props[5])
    //           .then((r) => console.log(r))
    //           .catch((err) => console.log(err));
    //     })
    //     .catch((err) => console.log(err));
    //   console.log(res);
    //   refetch();
    // }
    if (e === "update") {
      console.log("update", props);
      setOpen(true);
      reset({
        title: props[0],
        description: props[1],
        keywords: props[2]
      });
      setState(props[3]);
     

    
    }
  };

  useEffect(() => {
    const array:any = []
    firebase.firestore().collection('services').get().then(r=> {
       const array:any = [];
       const docs = r.docs;
       for (const doc of docs) {

        //@ts-ignore
        array.push([doc.data().title, doc.data().description,doc.data().keywords,doc.id])
         
       }
    
      

      setlist(array)
    
    })
    
    return () => {
      
    }
  }, [open])



 
  if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );

  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h5>Parent Services</h5>
                  <p>(Business immigration & Personal immigration)</p>
                </GridItem>
                
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
              >
                {/* @ts-ignore */}
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogTitle id="form-dialog-title">
                  Update 
                </DialogTitle>

                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                   

                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Title"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Title is required" }}
                    />

                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextareaAutosize
                          placeholder="description"
                          className="form-control w-100"
                          value={value}
                          onChange={onChange}
                       
                        />
                       
                      )}
                      rules={{ required: "Description is required" }}
                    />
                    <Controller
                      name="keywords"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                        label="keyword"
                        className="form-control w-100"
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                      )}
                      rules={{ required: "keyword is required" }}
                    />

                   

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>
          
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Title", "Description","Keywords", "Actions"]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

/* id: ID!
name: String
title: String
keywords: String
description: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
