import React, { useEffect } from "react";
import firebase from '../../../firebase'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
  TextareaAutosize,
  FormLabel
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";



var slugify = require("slugify");

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState<any>("");
  const [state, setState] = React.useState("");
  const [list, setlist] = React.useState<any>([]);
 

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
   
    setState("");
    reset({
      id:"",
      title:"",
      "description":"",
      keywords:""
    })
    setOpen(false);
  };
 

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({id,title, keywords, description }: any) => {
    // call mutation
     
    

    setloading("true");

    if (state) {
      //update


      await firebase.firestore().collection('pages').doc(id).update({
        title, description, keywords
      }).then(r=>r)


   


      setloading("");
      setState("");
      setOpen(false);
      reset({
        id:'', title:'', keywords:'', description:''
      });
      return ;
    }
    await firebase.firestore().collection('pages').doc(id).set({
      title, description, keywords
    }).then(r=>r)

    setloading("");
    setOpen(false);
    reset({
      id:'', title:'', keywords:'', description:''
    });
  };
  const handleClick = (props: any, e: any) => {
    // if (e === "delete") {
    //   remove({
    //     variables: { input: { id: props[4] } },
    //     refetchQueries: [{ query: gql(listPages) }],
    //   }).catch(err=>console.log(err));
    // }
    if (e === "update") {
      setOpen(true);
      reset({
        id:props[0], title: props[1], description:props[2] , keywords: props[3],
      });
      setState(props[0]);
      
    }
  };
  useEffect(() => {
    const array: any  = [];

     firebase
    .firestore()
    .collection("pages")
    .get()
    .then((r:any)=>  {
      const docs = r.docs;
      for (const doc of docs) { 
        array.push([
          doc.id,
          doc.data().title,
          doc.data().description,
          doc.data().keywords
        ]);
      


      }
      setlist(array)

    })

    
    return () => {
      
    }
  }, [open])

  if (!list.length)
    return (
      <div className="container text-center h-100">
        <CircularProgress
          style={{
            position: "relative",
            top: "50%",
          }}
        />
      </div>
    );

 

  return (
    <>


    
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1>Pages</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add New Pages
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">
                  Add New Setting
                </DialogTitle>
                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="id"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Id"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          disabled={state ? true:false}
                        />
                      )}
                      rules={{ required: "Name is required" }}
                    />
                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Title"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Title is required" }}
                    />

                    <Controller
                      name="keywords"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        
                        <TextField
                          label="Keywords"
                          className="form-control w-100"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Keywords is required" }}
                    />

                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                         <FormLabel className="my-2 ml-auto" >Description</FormLabel>
                         <TextareaAutosize
                          value={value}
                          className="form-control w-100"
                          onChange={onChange}
                          rowsMax={4}
                          aria-label="Description"
                          placeholder="Description"
                          rows="5"
                        />
                        </>
                     
                      )}
                      rules={{ required: "Description is required" }}
                    />

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Id",
                  "Title",
                  "Description",
                  "Keywords",
                  "Actions",
                ]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    

    </>
  );
}

/* id: ID!
name: String
title: String
keywords: String
description: String
slug: String
createdTime: AWSDateTime
updateTime: AWSDateTime
modules: [Module] @connection(name:"PageModules") */
