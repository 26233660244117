import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
// core components
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Table from "./Datatable";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import { nanoid } from "nanoid";
import firebase from '../../../firebase';
// import Compress from "compress.js";


// const compress = new Compress();

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
}));

export default function TableList() {
  const classes = useStyles();
  const [loadingButton, setloading] = React.useState("");
  const [state, setState] = React.useState("");
  const [fileurl, setFileUrl] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [list, setlist] = React.useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (state === "update") {
      reset({
        id: "",
        value: "",
      });
    }
    setState("");
    setFileUrl("");
    setSelectedFile("");
    setOpen(false);
  };

  const {
    register,
    // @ts-ignore
    errors,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    control,
  } = useForm();

  const onSubmit = async ({ id, value }: any) => {
    // call mutation
    let file = {};

    // upload file
    if (selectedFile) {
      // @ts-ignore
      file = await uploadFile(selectedFile);
    }

    console.log(file);

    console.log(id, value);

    setloading("true");

    if (state === "update") {
      console.log(id, value);
    
      await firebase.firestore().collection('settings').doc('web_settings').update({
       [id]:value 
      }).then(r=>r)


      setloading("");
      setState("");
      setOpen(false);
      setFileUrl("");
      setSelectedFile("");
      reset({
        id: "",
        value: "",
      });
      return;
    }

    await firebase.firestore().collection('settings').doc('web_settings').update({
      [id]:value 
     }).then(r=>r)

    setloading("");
    setOpen(false);
    setFileUrl("");
    setSelectedFile("");
    reset({
      id: "",
      value: "",
    });
  };
  const handleClick = async (props: any, e: any) => {
    console.log(props)
    if (e === "delete") {
     
    }
    if (e === "update") {
      setOpen(true);
      reset({
        id: props[0],
        value: props[1],
      });
      setState("update");
    }
  };

  const onFileSelected = async (event: any) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);
    // File Type or Size validation or make resize
    setFileUrl(URL.createObjectURL(selectedFile));
  };

  const uploadFile = async (selectedFile: any) => {
    const uid = nanoid(12);
    if (!selectedFile) return null;
    // return await Storage.put(uid + selectedFile.name, selectedFile, {
    //   contentType: selectedFile.type,
    // })
    //   .then(async (res: any) => {
    //     // @ts-ignore
    //     // uploadedFiles.push(URL.createObjectURL(file))
    //     // console.log(result);
    //     // const image = {
    //     //   bucket: awsExports.aws_user_files_s3_bucket,
    //     //   region: awsExports.aws_user_files_s3_bucket_region,
    //     //   id: uid+ file.name,
    //     // };

    //     // console.log(image);
    //     // images.push(image)
    //     // // @ts-ignore
    //     // // Storage.get(result.id).then(r=>console.log(r));

    //     return await Storage.get(res.key)
    //       .then((result) => {
    //         return { fileUrl: s3BaseUrl + res.key, fileKey: res.key };
    //       })
    //       .catch((err) => console.log(err));
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
  };

  useEffect(() => {

    firebase.firestore().collection('settings').doc('web_settings').get().then(r=>{
      const array:any  = []
      const array1:any  = []
      //@ts-ignore
      const obj = new Object(r.data())
      console.log(obj)
    
      for (const key in obj ) {
        
        //@ts-ignore
        array.push({key,objKey:obj[key]})
       
      }

      array.sort((a:any, b:any) => a.key.localeCompare(b.key)).map((item:any)=>  {

       
        array1.push([item.key,item.objKey])
       
      });


      setlist(array1)


          
    
            
    });
  
 
    
   
    
    return () => {
      
    }
  }, [open])

  // if (loading)
  //   return (
  //     <div className="container text-center h-100">
  //       <CircularProgress
  //         style={{
  //           position: "relative",
  //           top: "50%",
  //         }}
  //       />
  //     </div>
  //   );

  // if (error) return <div>{`Error! ${error.message}`}</div>;

  return (
    <>
      <GridContainer>
        <GridItem
          // @ts-ignore
          xs={12}
          sm={12}
          md={12}
        >
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem
                  // @ts-ignore
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <h1> Website Settings</h1>
                </GridItem>
                <GridItem
                  // @ts-ignore
                  xs={6}
                  sm={3}
                  md={6}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                    className="h-100"
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                  >
                    Add New Setting
                  </Button>
                </GridItem>
              </GridContainer>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Add New Setting
                </DialogTitle>
                <DialogContent>
                  <form
                    // @ts-ignore
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="id"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="id"
                          variant="filled"
                          disabled={state === "update" ? true : false}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{
                        required: state === "update" ? false : "Id is required",
                      }}
                    />
                    <Controller
                      name="value"
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          label="Value"
                          variant="filled"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                      rules={{ required: "Value is required" }}
                    />

                    <Button variant="contained" component="label">
                      Upload File
                      <input type="file" hidden onChange={onFileSelected} />
                    </Button>

                    {fileurl && <img src={fileurl} height="50" alt="image" />}

                    <div>
                      <Button variant="contained" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Save &nbsp;
                        {loadingButton && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </CardHeader>

            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Id", "Value", "Actions"]}
                tableData={list}
                handleClick={handleClick}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
